
@font-face {
	font-family: 'BNR'; 
	src: url(../src/fonts/bnr.ttf); 
}

@font-face {
	font-family: 'Manrope-ExtraLight'; 
	src: url(../src/fonts/Manrope-ExtraLight.ttf); 
}

@font-face {
	font-family: 'Manrope-Light'; 
	src: url(../src/fonts/Manrope-Light.ttf); 
}

@font-face {
	font-family: 'Manrope-Regular'; 
	src: url(../src/fonts/Manrope-Regular.ttf); 
}

@font-face {
	font-family: 'Manrope-Medium'; 
	src: url(../src/fonts/Manrope-Medium.ttf); 
}

@font-face {
	font-family: 'Manrope-Semibold'; 
	src: url(../src/fonts/Manrope-SemiBold.ttf); 
}

@font-face {
	font-family: 'Manrope-Bold'; 
	src: url(../src/fonts/Manrope-Bold.ttf); 
}

@font-face {
	font-family: 'Manrope-Extrabold'; 
	src: url(../src/fonts/Manrope-ExtraBold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Light'; 
	src: url(../src/fonts/Montserrat-Light.ttf); 
}

@font-face {
	font-family: 'Montserrat-Regular'; 
	src: url(../src/fonts/Montserrat-Regular.ttf); 
}

@font-face {
	font-family: 'Montserrat-Medium'; 
	src: url(../src/fonts/Montserrat-Medium.ttf); 
}

@font-face {
	font-family: 'Montserrat-Semibold'; 
	src: url(../src/fonts/Montserrat-Semibold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Bold'; 
	src: url(../src/fonts/Montserrat-Bold.ttf); 
}

@font-face {
	font-family: 'Montserrat-Extrabold'; 
	src: url(../src/fonts/Montserrat-Extrabold.ttf); 
}

@media print {

}

body, body * {
    font-family: "Montserrat-Light", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, с 1999 года*/
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    margin: unset;
 }

.logoheader {
    padding: 10px 8px 14px 8px;
}

.logo {
    height: 60px;
    background: url(../src/img/logo.svg) no-repeat center center;
    background-size: contain;
}

.logomenu {
    height: 100px;
    margin: 30px 8px 10px 8px;
    background: url(../src/img/logo.svg) no-repeat center center;
    background-size: contain;
}

.authlogo {
    display: flex;
    height: 180px;
    width: 100%;
    position: relative;
    top: 30px;
    background: url(../src/img/logo.svg) no-repeat center center;
    background-size: contain;
}

.datepickerfull {
    width: 100%;
}

.ant-menu {
    background: transparent;
    color: #ffffff;
}

.ant-menu, .ant-menu * {
    font-family: "Montserrat-Medium" !important;
}

.dividerwhite {
    border-top: 1px solid rgb(255, 255, 255);
}

.ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover, .ant-menu-item-selected {
    color: #ffffff;
    background: #ffffff1a;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
    background: rgb(230 230 255 / 2%);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff14;
}

.ant-input-affix-wrapper {
    border-radius: 50px !important;
}

.mobilevis {
    display: none;
}

.headerfull {
    background: #5274b5;
    -webkit-box-shadow: 0px 4px 30px 0px rgb(126 126 126 / 20%);
    box-shadow: 0px 4px 30px 0px rgb(126 126 126 / 20%);
}

.revfull {
    min-height: 200px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-menu-horizontal {
    display: flex;
    justify-content: center;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: #001a29;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #002438;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: transparent;
    border-radius: 30px;
    min-height: 200px;
}

.cardnone {
    background: none!important;
    box-shadow: none!important;
}

.bordershadow {
    padding: 30px;
    border-radius: 30px;
    background: #ffffff;
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 20%);
}

.ant-form-item:last-child {
    margin-bottom: 0;
}

.wrow {
    display: flex;
    flex-wrap: wrap;
}

.nomargin {
    margin:0 !important
}

.wcol {
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    min-width: 300px;
    flex: 1 1 calc(100%/4 - 4rem);
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.wcol > .ant-badge {
    height: 100%;
}

.max400 {
    max-width: 400px !important;
    margin-left: auto;
    margin-right: auto;
}

.listanswer {
    padding: 2px 10px;
    background: #edf6ff;
    border-radius: 30px;
}

.colors {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    max-width: 150px;
}

.colors > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 calc(100%/4 - 2px);
    margin: 5px 0;
}

.maxtask:before{
    content:"";
    display:block;
    height:30px;
    position:relative;
    top:180px;
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(247, 247, 247, .75))
}

.maxtask::after {
    content: "";
    height: 100%;
    width: 100%;
    background: transparent;
    position: absolute;
    z-index: 2;
    padding: 20px;
    top: 0;
    left: 0;
}

.c1.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7 75%)}
.c2.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffc9c5 75%)}
.c3.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffeaad 75%)}
.c4.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #fffbcb 75%)}
.c5.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e4ffc4 75%)}
.c6.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d7fff6 75%)}
.c7.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d8f8ff 75%)}
.c8.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #c7dcff 75%)}
.c9.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ead2ff 75%)}
.c10.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffe1f1 75%)}
.c11.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffedd8 75%)}
.c12.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e8eaed 75%)}

.ant-layout, .ant-layout * {
    overflow: visible;
}

.lasttask {
    padding: 0 20px 20px 20px;
    margin-top: auto;
    margin-bottom: 0 !important;
}

.maxtask {
    max-height: 200px;
    overflow: hidden;
    height: 100%;
}

.floattask {
    display: flex;
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 100;
    cursor: pointer;
}

.floattask > span {
    cursor: pointer;
    background: url(/src/img/taskadd.svg) no-repeat center center;
    background-size: contain;
    width: 60px;
    height: 60px;
    transition: all .3s ease;
}

.floattask > span:hover {
    transform: scale(1.05);
}

.theme {
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #444444;
}

.taskheader {
    padding: 20px 0;
    display: flex;
}

.taskheader * {
    font-family: "Montserrat-SemiBold" !important;
}

.tasktop {
    z-index: 100;
    margin-left: auto;
}

.elementlock ~ * {
    z-index: 3;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: #fafafa8f !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: #ffffffba !important;
}

.ant-tabs-tab {
    border-radius: 20px 20px 5px 5px !important;
}

.c_patients .ant-card {
    border-radius: 20px;
}

.cardlist {
    align-items: stretch;
}

.cardlist .ant-card {
    height: 100%;
}

.ant-card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.15);
    -moz-box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.15);
    box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.15);
    background: rgba(255, 255, 255, .95);
}

.cardlist > * {
    width: 250px;
}

.cardlist .ant-card-actions {
    margin-top: auto;
}

.ant-table {
    background: transparent;
}

.elementlock > * {
    padding-left: 20px;
    padding-right: 20px;
}

.taskhide {
    opacity: .8;
    padding: 0;
    cursor: pointer;
    transition: all .3s ease;
    min-height: 200px;
}

.taskhide:first-of-type:last-child {
    max-width: 500px;
}

.ant-dropdown-menu-item:first-of-type {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.ant-dropdown-menu-item:last-of-type {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.ant-dropdown-menu {
    border-radius: 15px;
    padding: 0;
}

.taskhide:nth-child(4n+1):last-child {
    max-width: 500px;
}

.ant-card-meta-title {
    white-space: pre-wrap;
}

.ant-image-img {
    object-fit: contain;
}

.ant-btn-primary {
    border-radius: 30px;
}

.ant-btn-dashed {
    border-radius: 30px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    float: none;
    text-align: center;
}

.ant-pagination {
    text-align: center;
}

.filelist .ant-col, .filelist .ant-col, .filelist .ant-col > .ant-list-item, .filelist .ant-col > .ant-list-item > .ant-card {
    height: 100%;
}

.filelist .ant-row {
    justify-content: center;
    align-items: stretch;
}

.badgemenu {
    margin-right: 5px;
}

.filelist .ant-row > div {
    margin-bottom: 20px;
}

.animdown > div {
    animation: carding 1s ease;
}

.w100 > .ant-space-item:nth-child(2) {
    width: 100%;
}

.taskhide:hover {
    -webkit-box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    transform: scale(1.01);
}

.ant-popover-inner {
    border-radius: 15px;
}

.ant-alert {
    border-radius: 20px !important;
}

.fcenter {
    text-align: center;
    display: flex;
    justify-content: center;
}

.fright {
    text-align: center;
    display: flex;
    justify-content: flex-end;
}

.authp {
    margin-bottom: 20px;
}

.authp u {
    font-size: 12px;
    cursor: pointer;
    text-decoration-color: #8a8a8a;
    color: #8a8a8a;
}

.filters {
    opacity: 0;
}

.table-padding {
    padding: 0 0 30px 0;
    border-radius: 30px;
    overflow: hidden;
}

.ant-table-header {
    border-radius: 30px;
    margin-bottom: 10px;
}

.table-padding .ant-table-thead > tr {
    background: rgb(121,144,234);
    background-size: cover !important;
}

.table-padding .ant-table-thead > tr > th {
    background: unset;
    color: rgba(255, 255, 255, 0.85);
    font-family: 'Manrope-Bold' !important;
    font-size: 1.5em;
}

.table-padding .ant-pagination {
    margin: 16px;
}

.table-padding .ant-table-thead {
    -webkit-box-shadow: 0px 0px 30px 0px rgb(126 126 126 / 20%);
    box-shadow: 0px 0px 30px 0px rgb(126 126 126 / 20%);
}

.shadowscreen {
    -webkit-animation: shadowscreen 3s infinite ease-in-out;
    animation: shadowscreen 3s infinite ease-in-out;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 20%);
    background: rgba(247, 247, 247, .95);
    border-radius: 30px;
}

.hoverlist .ant-list-item {
    transition: all .3s ease;
    cursor: pointer;
}

.hoverlist .ant-list-item:hover {
    transform: translateY(5px);
}

modal-mini {
    max-width: calc(100vw - 10%);
}

.modal-mini {
    max-width: calc(100vw - 10%);
}

.modal-mini .ant-modal-header {
    display: none;
}

.modal-mini > .ant-modal-content {
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 30%);
    background: rgb(220,224,250);
    background: linear-gradient(0deg, rgba(220,224,250,1) 0%, rgba(229,232,242,1) 20%, rgba(229,232,242,1) 80%, rgba(196,200,228,1) 100%);
    background-position-x: right;
}

.ant-form-item-label > label {
    color: #484848;
    font-family: 'Montserrat-Regular' !important;
}

.modal-mini .ant-form-item-label > label {
    color: #355b7d !important;
    font-family: 'Montserrat-Semibold' !important;
}

.modal-mini-header {
    padding-top: 20px;
    text-align: center;
}

.modal-mini-body {
    padding: 24px;
}

.modal-mini  .ant-modal-body {
    padding: 0;
}

.modal-mini-header::after {
    content: "";
    display: block;
    height: 1px;
    background: #97bee1;
    margin-bottom: 30px;
}

.modal-mini-header-title {
    color: #355b7d !important;
    font-family: 'Montserrat-Semibold' !important;
    font-size: 16px;
    margin: 10px;
}

.modal-mini-header-title.title-big {
    font-size: 22px;
}

.modal-mini .ant-list-item strong {
    color: #355b7d
}

.des-button {
    background: rgb(50,110,150);
    background: linear-gradient(165deg, rgba(50,110,150,1) 0%, rgba(87,113,162,1) 100%);
    color: #ffffff;
    border: none;
    box-shadow: 0 0px 15px 0 rgb(0 0 0 / 20%);
}

.des-button > * {
    font-family: 'Montserrat-Regular' !important;
}

.des-button.ant-btn-dangerous {
    background: rgb(240, 160, 160);
    background: linear-gradient(180deg, rgb(240, 160, 160) 0%, rgb(190, 78, 78) 100%);
}

.des-green {
    background: rgb(115, 142, 94);
    background: linear-gradient(165deg, rgb(115, 142, 94) 0%, rgb(91, 115, 72) 100%);
}

.des-button:hover, .des-button:focus {
    background: linear-gradient(165deg, rgb(42, 98, 135) 0%, rgb(71, 95, 140)100%) !important;
    color: #ffffff !important;
}

.des-green:hover, .des-green:focus {
    background: linear-gradient(165deg, rgb(99, 124, 81) 0%, rgb(83, 104, 65)100%) !important;
    color: #ffffff !important;
}

.des-button.ant-btn-dangerous:hover, .des-button.ant-btn-dangerous:focus {
    background: linear-gradient(180deg, rgb(216, 132, 132) 0%, rgb(163, 62, 62) 100%) !important;
    color: #ffffff !important;
}

.modal-mini .ant-input-affix-wrapper, .modal-mini .ant-input-affix-wrapper, .modal-mini .ant-picker, .modal-mini .ant-select:not(.ant-select-customize-input) .ant-select-selector, .modal-mini .ant-form-item-control-input-content > .ant-input, .modal-mini textarea.ant-input {
    border: 1px solid #97bee1 !important;
    -webkit-box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
    -moz-box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
    box-shadow: 1px -1px 5px 0px rgba(34, 60, 80, 0.15) inset;
}

@keyframes shadowscreen {
    0% {
        -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    }
    50% {
        -webkit-box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
    }
    100% {
        -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    }
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
}

.authform {
    justify-content: center;
}

.ant-avatar > img, .ant-avatar .ant-image-img {
    border-radius: 50%;
}

.authpage {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadpage {
    justify-content: center;
}

.bg-auth {
    background: url(../src/img/bg.jpg) fixed no-repeat top center !important;
    background-size: cover !important;
}

.buttmar > button {
    margin-right: 8px;
    margin-bottom: 12px;
}

.filtersvisible {
    position: absolute;
    opacity: 0;
    top: -1000px;
    width: 0;
    height: 0;
}

.filtersmargin {
    margin-right: 16px;
}

.lglobal, .loadpage {
    background: url(/src/img/bgfull.png) no-repeat top,#badae9;
    background-size: cover;
    background-attachment: fixed;
}

.ant-drawer-content {
    backdrop-filter: blur(2px);
    background-color: #ffffff21;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0;
}

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    padding: 0 16px 0 32px;
}

.ant-layout-sider {
    backdrop-filter: blur(2px);
    background-color: #ffffff21;
    color: #ffffff;
    border-right: 1px solid #ffffff;
}

.inputtrans * {
    color: #ffffff;
    background: transparent;
    background-color: transparent !important;
}

.whitebut * {
    border-color: #ffffff !important;
}

.whitebut .ant-btn:hover, .ant-btn:focus {
    color: #000000;
}

.inputtrans .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #ffffff;
}

.inputtrans .ant-input-affix-wrapper {
    border: 1px solid #ffffff;
}

.inputtrans .ant-btn {
    border-color: #ffffff;
    width: 22px;
    height: 22px;
    min-width: 22px;
}

.bnone {
    border: transparent !important;
}

.ant-drawer-body {
    padding: 0;
}

.menubutton {
    position: fixed;
    display: flex;
    left: 0;
    top: 22px;
    left: 22px;
    font-size: 18px;
    width: 40px;
    height: 40px;
    box-shadow: 5px 0 5px 0 rgb(0 0 0 / 15%);
    border-radius: 8px;
    background: #fff;
    color: #6083a3;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.tworow {
    display: flex;
    flex-wrap: wrap;
}

.tworow > div {
    width: 50%;
    min-width: 50%;
}

.ant-table-cell-scrollbar {
    box-shadow: unset;
}

@media screen and (max-width: 1300px) {

    .leftmenu {
        display: none;
    }
}

@media screen and (max-width: 1100px) {

    .table-padding .ant-table-thead > tr > th {
        font-size: 1.2em;
    }

    .tworow {
        flex-direction: column;
    }

    .tworow > div {
        width: 100%;
        margin-bottom: 50px;
    }

    .tworow > div:last-of-type {
        margin-bottom: unset;
    }

    .filtershidden {
        display: none;
    }
    .filtersvisible {
        position: unset;
        opacity: 1;
        top: unset;
        width: unset;
        height: unset;
    }
    .filtersmargin {
        margin-right: 0;
    }
}

@media screen and (max-width: 1000px) {

    .rbc-toolbar-label {
        font-size: 16px;
        padding-right: unset;
    }
}

@media screen and (max-width: 700px) {

    .table-padding .ant-table-thead > tr > th {
        font-size: 1em;
    }

    .cardlist {
        width: 100%;
    }

    .cardlist > * {
        width: 100%;
    }
}

@media screen and (max-width: 650px) {

    .wcol:last-child:first-child {
        max-width: 100%;
    }
    .wcol {
        margin: 20px 0;
    }
    
    .bg-auth {
        background: url(../src/img/bgm.jpg) fixed no-repeat top center !important;
    }

    .lglobal, .loadpage {
        background: url(../src/img/bgfullm.png) fixed no-repeat top center !important;
    }
    
}

@media screen and (max-width: 600px) {

    .usercard {
        flex-direction: column !important;
    }

    .usercard > div:first-child {
        display: flex;
        justify-content: center;
    }

    .usercard > div {
        margin: 10px 0 !important;
    }
    
}

@media screen and (max-width: 500px) {
    
    .floattask > span {
        width: 60px;
        height: 60px;
    }

    .menubutton {
        top: 11px;
        left: 11px;
    }

    .logo {
        height: 40px;
    } 

    .wcol {
        min-width: 100%;
    }
}

@media screen and (max-width: 370px) {
    .row_min_vertical {
        flex-direction: column;
    }

    .row_min_vertical > div {
        margin-top: 10px;
    }
}

.gradient {
	color: transparent;    
	-webkit-background-clip: text;
	background-clip: text;
	background-image: linear-gradient(135deg, #0daaeb, #9054cb);
}

.auth_remember span {
    color: #FFFFFF !important;
    background-color: none !important;
}

.authpageblock {
    border-radius: 40px;
    background: rgb(96,146,187);
    background: linear-gradient(30deg, rgba(96,146,187,0.85) 0%, rgba(94,149,185,0.85) 100%);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    padding: 50px 50px 50px;
    max-width: 400px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    box-shadow: 0 0 28px 5px rgb(5 44 172 / 25%), 0px 0px 30px 1px rgba(255, 255, 255, 0.26) inset;
}

.authtitle {
    font-family: 'Manrope-Semibold' !important;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
}

.inauth {
    border-radius: 25px;
    height: 40px;
    color: #8a8a8a;
    padding: 0 11px 0 0 !important;
    background: #cfe5f2;
    border-color: #d9d9d9 !important;
}

.inauth input:-webkit-autofill,
.inauth input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.inauth input::placeholder {
    color: #5F7B9F;
  }

.inauth input {
    font-family: 'Manrope-Semibold' !important;
    caret-color: #5496a1;
    background-color: #cfe5f2;
    color: #5F7B9F !important;
}

.authicon {
    background: #2d4373a1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 38px;
}

.inauths {
    border-radius: 25px;
    width: 100px;
    background: rgb(255 255 255 / 63%) !important;
    border-color: rgb(255 255 255 / 63%) !important;
    color: #5F7B9F !important;
}

.inauths * {
    font-family: 'Manrope-Semibold' !important;
}

.inauths:hover {
    background: rgb(255 255 255 / 53%) !important;
    border-color: rgb(255 255 255 / 53%) !important;
}

.newpass {
    color: #ffffff;
    font-family: 'Montserrat-Semibold' !important;
    cursor: pointer;
}

.newpass:hover {
    text-decoration: underline;
}

.tableimg {
    max-width: 50px;
}

.tableimg > img {
    width: 100%;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: rgba(212, 212, 212, 100%);
}

::selection {
    background: #dceaff!important;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border-radius: 50px;
}

.ant-pagination-item {
    border-radius: 50px;
}

.mb0 {
    margin-bottom: 0 !important;
}

.ant-btn:hover, .ant-btn:focus {
    color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
}

.ant-pagination-item-active {
    border-color: rgb(254, 254, 255);
}

.ant-pagination-item-active:focus-visible, .ant-pagination-item-active:hover {
    border-color: rgb(0, 0, 0);
}

.ant-pagination-item-active:focus-visible a, .ant-pagination-item-active:hover a {
    color: rgb(0, 0, 0);
}

.ant-pagination-item-active a {
    color: #d9d9d9;
}

.ant-pagination-item:focus-visible, .ant-pagination-item:hover {
    border-color: rgb(254, 254, 255);
}

.ant-pagination-item:focus-visible a, .ant-pagination-item:hover a {
    color: rgb(254, 254, 255);
}

.ant-input:hover {
    border-color: rgb(0, 0, 0);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: rgb(0, 0, 0);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: rgb(0, 0, 0);
    box-shadow: 0 0 0 2px rgba(254, 254, 255, 0.2);
}

.ant-picker-range .ant-picker-active-bar {
    background: rgb(254, 254, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid rgb(254, 254, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #555555;
    color: rgb(254, 254, 255);
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after, .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    left: 6px;
    border-left: 1px dashed rgb(255, 255, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed rgb(255, 255, 255);
    border-bottom: 1px dashed rgb(255, 255, 255);
}

.ant-picker:hover, .ant-picker-focused {
    border-color: rgb(0, 0, 0);
}

.ant-picker-focused {
    box-shadow: 0 0 0 2px rgba(254, 254, 255, 20%);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: rgb(203, 203, 255);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: rgb(230, 230, 255);
}

.ant-picker-focused {
    border-color: rgb(0, 0, 0);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid rgb(0, 0, 0);
}

.ant-btn-dashed:active {
    color: rgb(0, 0, 0);
    background: #fff;
    border-color: rgb(0, 0, 0);
}

.ant-btn-dashed:hover, .ant-btn-dashed:focus {
    color: rgb(0, 0, 0);
    background: #fff;
    border-color: rgb(0, 0, 0);
}

.ant-input:focus, .ant-input-focused {
    border-color: rgb(0, 0, 0);
    -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: rgb(0, 0, 0);
}

.ant-input-affix-wrapper:hover {
    border-color: rgb(0, 0, 0);
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: rgb(0, 0, 0);
    -webkit-box-shadow: 0 0 0 2px rgba(254, 254, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(254, 254, 255, 0.2);
}

.ant-radio-checked .ant-radio-inner {
    border-color: rgb(181, 181, 255);
}

.ant-radio-checked::after {
    border: 1px solid rgb(181, 181, 255);
}

.ant-radio-inner::after {
    background-color: rgb(181, 181, 255);
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: rgb(181, 181, 255);
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(181, 181, 255);
    border-color: rgb(181, 181, 255);
}

.ant-checkbox-checked::after {
    border: 1px solid rgb(181, 181, 255);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: rgb(181, 181, 255);
}

.bold {
    font-weight: 800;
}

.ant-input, .ant-input-affix-wrapper, .ant-input-number, .ant-modal-content, .ant-picker, .ant-popover-buttons button, .ant-select-multiple .ant-select-selector, .ant-select-single .ant-select-selector {
    border-radius: 30px!important;
}

.ant-modal-header {
    border-radius: 30px 30px 0 0;
}

.wowload {
    -webkit-animation: wowload 1s ease;
    animation: wowload 1s ease;
}

.ant-card-head-title {
    font-family: 'Manrope-Bold' !important;
    overflow: hidden !important;
    text-align: center;
}

.tooglecolor .ant-card-head {
    color: #ffffff;
    border-radius: 20px;
    background: rgb(121,144,234);
    background: linear-gradient(0deg, rgba(121,144,234,1) 0%, rgba(122,146,237,1) 100%);
    background-size: cover !important;
    -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    border-bottom: none;
}

.tooglecolor > div > span:nth-of-type(8n+1) .ant-card-head {
    background: rgb(233,114,130);
    background: linear-gradient(0deg, rgba(233,114,130,.9) 0%, rgba(232,80,100,.9) 100%);
}

.tooglecolor > div > span:nth-of-type(8n+2) .ant-card-head {
    background: rgb(233,114,130);
    background: linear-gradient(0deg, rgba(233,114,130,.9) 0%, rgba(232,80,100,.9) 100%);
}

.tooglecolor > div > span:nth-of-type(8n+3) .ant-card-head {
    background: rgb(233,114,130);
    background: linear-gradient(0deg, rgba(233,114,130,.9) 0%, rgba(232,80,100,.9) 100%);
}

.tooglecolor > div > span:nth-of-type(8n+4) .ant-card-head {
    background: rgb(233,114,130);
    background: linear-gradient(0deg, rgba(233,114,130,.9) 0%, rgba(232,80,100,.9) 100%);
}

.tooglebackground .ant-card-body {
    background-size: contain !important;
}

.tooglebackground > div > span:nth-of-type(8n+1) .ant-card-body {
}

.tooglebackground > div > span:nth-of-type(8n+2) .ant-card-body {
}

.tooglebackground > div > span:nth-of-type(8n+3) .ant-card-body {
}

.tooglebackground > div > span:nth-of-type(8n+4) .ant-card-body {
}

.ant-card-meta-description {
    display: inline-block;
    overflow: hidden;
    overflow-wrap: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 50px;
    width: 100%;
}

@keyframes wowload {
    0% {
       opacity: 0;
    }
    100% {
       opacity: 1;
    }
}

.dempty * {
    font-weight: 600;
    color: #ffffff !important;
}

.column900 {
    align-items: center;
}

.column900 > .ant-col {
    width: 100%;
    max-width: 900px;
    margin-bottom: 24px;
}

.column900 > .ant-col:last-of-type {
    width: 100%;
    max-width: 900px;
    margin-bottom: 0;
}

html {
    --antd-wave-shadow-color: rgb(254, 254, 255);
}

.spincenter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.ant-select-dropdown {
    border-radius: 15px;
    padding: 0;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgb(240 240 255);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
@media print {
    
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #a2bbd16c;
}

.profile_full {
    overflow: hidden;
    color: #575757 !important;
}

.profile_full > .ant-result-info {
    background: rgb(114, 152, 233);
    background: linear-gradient(0deg, rgba(114, 152, 233,.9) 0%, rgba(80, 110, 232, 0.9) 100%);
    -webkit-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
}

.profile_full strong {
    font-family: 'Manrope-Bold' !important;
    font-weight: unset;
}

.coldesc {
    padding: 50px;
}

.profile_icon {
    display: flex;
    margin: auto;
    background: #ffffff;
    border-radius: 100px;
    width: fit-content;
}

.profile_icon > img {
    padding: 15px;
    width: 90px;
    height: 90px;
}

.icon_max {
    background: rgb(233,114,130);
    background: linear-gradient(0deg, rgba(233,114,130,.9) 0%, rgba(232,80,100,.9) 90%);
}

.icon_max > img {
    width: 100%;
    height: 100%;
    padding: 5px;
}

.profile_title {
    font-family: 'Manrope-Bold' !important;
    color: #ffffff;
    line-height: 30px;
}

.profile_subtitle {
    display: flex;
    margin: auto;
    margin-top: 20px;
    font-family: 'Manrope-SemiBold' !important;
    color: #ffffff;
    max-width: 500px;
}

/* Календарь */

.rbc-row-content {
    overflow: auto;
}

.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    background-position: right top;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    padding-top: 20px;
}

.rbc-toolbar {
    background: rgb(114, 152, 233);
    background: linear-gradient(0deg, rgba(114, 152, 233,.9) 0%, rgba(80, 110, 232, 0.9) 100%);
    color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    background-size: cover !important;
}

.ant-popover-inner, .ant-popover-arrow-content {
    background: #cbdbf7;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: #6a98e7 !important;
}

.rbc-calendar {
    padding: 10px;
}

.rbc-month-view {
    border: unset !important;
}

.rbc-header + .rbc-header {
    border-left: unset !important;
}

.rbc-header {
    border-bottom: unset !important;
}

.rbc-month-row:nth-child(2) > div:first-of-type > div {
    border-top: 1px solid #DDD;
}

.rbc-month-row > div:first-of-type > div:first-of-type {
    border-left: 1px solid #DDD;
}

.rbc-month-row > div:first-of-type > div:last-of-type {
    border-right: 1px solid #DDD;
}

.rbc-month-row:nth-child(2) > div:first-of-type > div:first-of-type {
    border-radius: 20px 0 0 0;
    border-left: 1px solid #DDD;
}

.rbc-month-row:nth-child(2) > div:first-of-type > div:last-of-type {
    border-radius: 0 20px 0 0;
    border-right: 1px solid #DDD;
}

.rbc-month-row:last-of-type > div:first-of-type > div:first-of-type {
    border-radius: 0 0 0 20px;
    border-left: 1px solid #DDD;
}

.rbc-month-row:last-of-type > div:first-of-type > div:last-of-type {
    border-radius: 0 0 20px 0;
    border-right: 1px solid #DDD;
}

.rbc-month-row:last-of-type > div:first-of-type > div {
    border-bottom: 1px solid #DDD;
}

.rbc-month-row + .rbc-month-row {
    border-top: unset;
}

.rbc-off-range-bg {
    background: #F5F4F4 !important;
}

.rbc-today {
    background-color: #e4ecf9 !important;
}

.rbc-month-view, .rbc-header + .rbc-header, .rbc-day-bg + .rbc-day-bg, .rbc-month-row + .rbc-month-row, .rbc-agenda-view table.rbc-agenda-table, .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr, .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-color: #D2D1D1 !important;
}

.rbc-toolbar button {
    border-color: transparent !important;
    margin-right: 3px !important;
}

.rbc-toolbar button {
    background-color: #edededbf !important;
    border-radius: 10px !important;
}

.rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0 !important;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-color: #ededed !important;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}

.rbc-toolbar button:hover {
    background-color: #edededbf !important;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
}

.rbc-toolbar-label {
    text-transform: capitalize;
    font-family: "Montserrat-Bold" !important;
    font-size: 20px;
    padding-right: 196px;
}

.button_circle {
    padding: 5px;
    border-radius: 50px;
    color: #ffffff;
    background: #007bff;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-custom {
    color: #fff;
    background-color: #6c757d;
    padding: 4px 16px;
    text-align: center;
    font-size: 16px;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 20px;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease;
}

.button-custom:hover {
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 30%);
    transform: scale(1.02);
}

.custom-green {
    background: #28a745;
}

.custom-red-new {
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Слой_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 300 150' style='enable-background:new 0 0 300 150;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23E94A60;%7D .st1%7Bfill:url(%23SVGID_1_);%7D%0A%3C/style%3E%3Crect class='st0' width='300' height='150'/%3E%3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='184.74' y1='-9.094947e-13' x2='184.74' y2='150' gradientTransform='matrix(1 0 0 -1 0 150)'%3E%3Cstop offset='0' style='stop-color:%23E97F8B'/%3E%3Cstop offset='1' style='stop-color:%23F5B0B3'/%3E%3C/linearGradient%3E%3Cpath class='st1' d='M300,0.08V150c-15.32,0-30.65,0-45.97,0c-28.54-17.38-59.8-38.65-92.17-64.56C125.27,56.16,69.48,0,69.48,0 S283.45,0.08,300,0.08z'/%3E%3C/svg%3E%0A");
    background-size: cover;
    border-color: #ff9dab !important;
    color: #ffffff !important;
}

.service_price {
    padding: 2px 10px;
    border-radius: 30px;
    border: 1px solid #7b7b7b;
    color: #575757;
}

.button_blue {
    background: #6a98e7;
    color: #ffffff !important;
    border-color: #6a98e7 !important;
}

.button_normal{
    padding: 2px 20px;
    border-radius: 30px;
    border: 1px solid #7b7b7b;
    color: #575757;
    cursor: pointer;
    transition: all .2s ease;
}

.button_normal:hover {
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%);
    transform: scale(1.02);
}

.cal-badge {
    color: #fff;
    background-color: #6c757d;
    padding: .25em .4em;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50px;
    display: inline-block;
}

.cal-badge-open {
    background-color: #ffffff;
    color: #1f2b44;
}

.cal-badge-close {
    background-color: #A6CE43;
}

.cal-badge-danger {
    background-color: #F8485E;
}

.cal-badge-warning {
    background-color: #ffc107;
}

.pay_button {
    padding: 10px 5px;
    background: #f8f8f8;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    transition: all .2s ease;
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
}

.pay_button:hover {
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 30%);
    -webkit-transform: scale(1.01) !important;
    -ms-transform: scale(1.01) !important;
    transform: scale(1.01) !important;
}

.pay_button > img {
    height: 30px;
    width: 150px;
    object-fit: contain;
}

.table-500 .ant-table-tbody {
    max-height: 500px;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #a2bbd16c;
}